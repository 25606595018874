import React, { useState, useEffect } from "react"
import axios from 'axios'
import { API } from "./../../config/api"
import { Container, Row } from "react-bootstrap"
import Layout from "../../components/layout"
import HeaderPages from "../../components/header-pages"
import Seo from "../../components/seo"

const TermConditionDetail = ({ params }) => {
    const param = params['flag']
    const [Data, setData] = useState()

    console.log("FLAG DATA: ", param)
    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${API.URL}/api/v1/faq/detail-terms?flag=${param}`
            )
            const link = `${API.URL}/api/v1/faq/detail-terms?flag=${param}`
            const data = response?.data?.data
            console.log("LINK: ", link);
            console.log("DATA: ", data);

            setData(response.data.data)
        } catch (error) {
            return {
                status: false,
                response: null,
                messages: error.message,
            }
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <>
            <Layout pageTitle="Syarat & Ketentuan | Klabbelanja">
                <Seo title="Syarat & Ketentuan | Klabbelanja" />
                <HeaderPages />
                <section className="blog-style-one blog-page" id="blog" style={{ marginRight: 15, marginLeft: 15 }}>
                    <Container fluid>
                        <Row>
                            <div
                                style={{ color: "black", width: '100%' }}
                                dangerouslySetInnerHTML={{ __html: Data?.content }}
                            />
                        </Row>
                    </Container>
                </section>
            </Layout>
        </>
    )
}

export default TermConditionDetail
